import React from 'react';
import { FaViber, FaWhatsapp, FaInstagram, FaEnvelope } from 'react-icons/fa';
import './style/footer.scss';

const Footer = () => {
    return (
        <footer className="footer" id="footer">
            <p>&copy; 2024 Dreamstrl. All rights reserved.</p>
            <p>Contact us at: +123456789</p>
            <p>Email: <a href="mailto:contact@yourcompany.com">contact@yourcompany.com</a></p>
            <div className="social-icons">
                <a href="viber://chat?number=+123456789" aria-label="Viber"><FaViber /></a>
                <a href="https://wa.me/123456789" aria-label="WhatsApp"><FaWhatsapp /></a>
                <a href="https://instagram.com/yourcompany" aria-label="Instagram"><FaInstagram /></a>
                <a href="mailto:contact@yourcompany.com" aria-label="Email"><FaEnvelope /></a>
            </div>
        </footer>
    );
};

export default Footer;