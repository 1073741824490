import React from 'react';
import { FaCar, FaCarCrash, FaTools } from 'react-icons/fa'; // Dodali smo auto ikonice
import './style/services.scss';

const servicesList = [
    {
        title: "Service 1",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
        icon: <FaCar />
    },
    {
        title: "Service 2",
        description: "Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        icon: <FaCarCrash />
    },
    {
        title: "Service 3",
        description: "Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris.",
        icon: <FaTools />
    }
]

const Services = () => {
    return (
        <section className="services" id="services">
            <h1>ŠLEP SLUŽBA VETIĆ</h1>
            <div className="services-content">
                <div className="services-text">
                    <h2>Our Services</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</p>
                </div>
                <div className="services-images">
                    {servicesList.map((service, idx) => (
                        <div key={idx} className="service-item">
                            {service.icon}
                            <div className="service-info">
                                <h3>{service.title}</h3>
                                <p>{service.description}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default Services;