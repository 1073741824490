import React, { useState, useEffect } from 'react';
import './style/hero.scss';

const images = [
    './assets/photo1.jpg',
    './assets/photo2.jpg',
    './assets/photo3.jpg',
    './assets/photo4.jpg',
    './assets/photo5.jpg',
    './assets/photo6.jpg',
    './assets/photo7.jpg',
    './assets/photo8.jpg',
];

const Hero = () => {
    const [visibleImages, setVisibleImages] = useState([0, 1, 2, 3]);

    useEffect(() => {
        const interval = setInterval(() => {
            setVisibleImages((prevImages) => {
                const nextIndex = (prevImages[3] + 1) % images.length;
                return [
                    prevImages[1],
                    prevImages[2],
                    prevImages[3],
                    nextIndex
                ];
            });
        }, 3000);

        return () => clearInterval(interval);
    }, []);

    return (

        <section className="hero" id="hero">

            <div className="hero-slideshow">
                {visibleImages.map((imageIndex, idx) => (
                    <div key={idx} className="hero-image-container">
                        <img
                            src={images[imageIndex]}
                            alt={`Slide ${imageIndex}`}
                            className="active"
                        />
                    </div>
                ))}
            </div>
        </section>
    );
};

export default Hero;