import React from 'react';
import './style/navbar.scss';
const Navbar = () => {
    return (
        <nav id="navbar" className="navbar">

            <ul className="navbar-menu">
                <li><a href="#navbar">Home</a></li>
                <li><a href="#services">Services</a></li>
                <li><a href="#about">About</a></li>
                <li><a href="#footer">Contact</a></li>
            </ul>
            <button className="navbar-button">Call Us</button>
        </nav>
    );
};

export default Navbar;